// stylelint-disable declaration-no-important

//
// Right side table of contents
//
.bd-toc {
  border-left: 1px solid #eee;
  @supports (position: sticky) {
    position: sticky;
    top: 5rem;
    height: calc(100vh - 5rem);
    overflow-y: auto;
  }
  order: 2;
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  font-size: 0.875rem;

  .section-nav {
    padding-left: 0;

    ul {
      padding-left: 1rem;

      ul {
        display: none;
      }
    }

    .toc-entry {
      display: block;

      a {
        display: block;
        padding: 0.125rem 1.5rem;
        color: #004D99;
        text-decoration: none;

        &:hover {
          color: $blue;
          text-decoration: none;
        }
      }
    }
  }
}

//
// Left side navigation
//
.bd-sidebar {
  order: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);

  .bd-links {
    padding: 0;

    .link-list-wrapper {
      ul.link-list > li > a {
        font-size: 0.889rem;
      }
    }

    @include media-breakpoint-up(md) {
      @supports (position: sticky) {
        max-height: calc(100vh - 5rem);
      }
    }

    // Override collapse behaviors
    @include media-breakpoint-up(md) {
      display: block !important;
    }
  }

  .nav {
    > li > a {
      display: inline-block;
      padding: 0.25rem 0.5rem 0.25rem 1.5rem;
      font-size: 16px;
      color: $gray-800;

      &:hover {
        color: $primary;
        background-color: transparent;
      }
    }

    > .active > a,
    > .active:hover > a {
      font-weight: 600;
      color: $primary;
      background-color: transparent;
    }
  }

  @include media-breakpoint-up(md) {
    @supports (position: sticky) {
      position: sticky;
      top: 5rem;
      height: calc(100vh - 5rem);
      overflow-y: auto;
    }
    padding: 1.5rem 0;
    border-right: 1px solid rgba(0, 0, 0, 0.1);
  }

  @include media-breakpoint-up(xl) {
    flex: 0 1 320px;
  }
}
