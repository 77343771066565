.bd-content {
  order: 1;

  // Hack the sticky header
  > h2[id],
  > h3[id],
  > h4[id] {
    pointer-events: none;

    :before {
      // These rules allow to anchor to be positioned under the sticky header
      content: "";
      display: block;
      height: 96px; /* fixed header height*/
      margin: -96px 0 0; /* negative fixed header height */
    }

    > div,
    > a {
      pointer-events: auto;
    }
  }

  > h2[id] {
    &::before {
      border-bottom: 1px solid #EDEDED;
      margin-bottom: 1.5rem;
    }
  }

  > ul li,
  > ol li {
    margin-bottom: .25rem;
    max-width: 40rem;
  }

  > p {
    max-width: 40rem;
  }
}

.bd-title {
  margin-top: 1rem;
  margin-bottom: .5rem;
}

.bd-lead {
  font-size: 1.125rem;
  font-weight: 300;

  @include media-breakpoint-up(sm) {
    margin-bottom: 1rem;
    font-size: 1.5rem;
  }
}

code.highlighter-rouge {
  background-color: #efc;
  a > & {
    color: #17324d;
  }
}
